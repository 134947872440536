<template>
  <div>
    <ProgressBar v-if="photoUploading" :uploading="photoUploading" />
    <div class="flex justify-between items-center">
      <Back>
        Back to collections
      </Back>
      <button
        class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white p-1 border border-blue-500 hover:border-transparent rounded-full h-15 w-15 flex items-center justify-center"
        @click="openModal = true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-10 w-10"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
      </button>
    </div>

    <div class="grid grid-cols-4 gap-10 px-16 py-6">
      <div class="relative" v-for="photo in photos" :key="photo.id">
          <div class="relative mb-5">
            <img class="object-cover h-80 w-full" :src="photo.image">
          </div>
          <button class="w-10 h-10 bg-red-600 text-white rounded-md absolute -top-4 -right-4" @click.prevent='deletePhoto(photo)'>X</button>
      </div>
    </div>

    <Modal :showing="openModal" @close="openModal = false">
      <h2>Add Photos to this collection</h2>

      <form class="flex flex-col">
        <div>
          <label
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-2 rounded"
          >
            <input
              type="file"
              @change="hendlePhotos"
              class="opacity-0 h-0 w-0"
              multiple
            />
            <span>Upload Photos</span>
          </label>

          <div class="flex">
            <div class="py-2 px-4" v-for="(photo, key) in prevPhotos" :key="key">
              <img
                class="w-10"
                :src="photo.path"
                :alt="photo.name"
              />
            </div>
          </div>
        </div>

        <button
          @click.prevent="addPhotos"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-2 rounded"
        >
          add photos
        </button>
      </form>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Back from '@/components/GoBack'
import ProgressBar from "@/components/ProgressBar";
import Modal from "@/components/Modal";

export default {
  name: "photos",
  components: {
    Modal,
    ProgressBar,
    Back
  },
  data() {
    return {
      collectionId: this.$route.params.id,
      categoryId: this.$route.params.categoryId,
      collection: {},
      files: [],
      openModal: false,
      prevPhotos: [],
      imageTypes: ["image/png", "image/jpeg", "image/jpg"],
    };
  },
  computed: {
    ...mapGetters(["photos", 'photoUploading', 'photoCollections']),

    getCollection() {
      return this.photoCollections.find(c => c.id == this.collectionId)
    }
  },
  methods: {
    hendlePhotos(e) {
      this.files = e.target.files
      if (this.files)
        this.files.forEach( file => {
          if (file && this.imageTypes.includes(file.type)) {
            const imageData = {
              name: file.name,
              path: URL.createObjectURL(file),
            }
            this.prevPhotos.push(imageData)
          }
        })
    },
    addPhotos() {
      const data = {
        collectionId: this.collectionId,
        categoryId: this.categoryId,
        collectionName: this.getCollection.name,
        images: this.files,
      };

      this.$store.dispatch("addPhotosToCollection", data);

      this.files = [];
      this.prevPhotos = [];

      this.openModal = false;
    },
    deletePhoto(photo) {
      console.log('ddd', photo)
      this.$store.dispatch("deletePhoto", photo)
    }
  },
  created() {
    this.$store.dispatch("getPhotos", {collectionId: this.collectionId, categoryId: this.categoryId});
    this.$store.dispatch("getCollections", this.categoryId);
  },
};
</script>

<style></style>
